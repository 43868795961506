'use client';

import { useSkaleEuropaFaucet } from 'src/lib/hooks';
import { CheckerProvider } from 'src/lib/wagmi/systems/Checker/Provider';
export function Providers({
  children
}: {
  children: React.ReactNode;
}) {
  useSkaleEuropaFaucet();
  return <CheckerProvider data-sentry-element="CheckerProvider" data-sentry-component="Providers" data-sentry-source-file="providers.tsx">{children}</CheckerProvider>;
}